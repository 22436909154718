import "./footer.css";
function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2024 C.RECORDS. Все права защищены.</p>
    </footer>
  );
}

export default Footer;
